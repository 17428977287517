(() => {
  const videoBtns = document.querySelectorAll(".c-video__btn");

  const playVideo = ({ video, icon }) => {
    video.play();

    icon.classList.replace("fa-play", "fa-pause");
  };

  const pauseVideo = ({ video, icon }) => {
    video.pause();

    icon.classList.replace("fa-pause", "fa-play");
  };

  const toggleVideo = ({ video, icon }) => {
    video.paused ? playVideo({ video, icon }) : pauseVideo({ video, icon });
  };

  const handleClick = (el) => {
    const { target } = el.dataset;
    const video = document.querySelector(target);
    const icon = el.querySelector("i");

    if (!video) throw "There's any video to be played.";

    toggleVideo({ video, icon });
  };

  videoBtns.forEach((videoBtn) => {
    videoBtn.addEventListener("click", () => {
      handleClick(videoBtn);
    });
  });
})();
