(() => {
  const servicesSlider = () => {
    const targets = [
      ...document.querySelectorAll(".s-services__cards .hexagon:not(.hexagon--empty)"),
    ];
    const swiper = new Swiper(".s-services__swiper", {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 5,
      navigation: {
        nextEl: ".s-services__next",
        prevEl: ".s-services__prev",
        disabledClass: "swiper__navigation--disabled",
      },
      breakpoints: {
        992: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 2,
        },
      },
    });

    targets.forEach((target) => {
      target = target.outerHTML;

      swiper.appendSlide(`<div class="swiper-slide">${target}</div>`);
    });
  };

  const productsSlider = () => {
    const targets = [
      ...document.querySelectorAll(
        ".s-products__links .s-products__link:not(.s-products__link--highlight)"
      ),
    ];
    const swiper = new Swiper(".s-products__swiper", {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 5,
      navigation: {
        nextEl: ".s-products__next",
        prevEl: ".s-products__prev",
        disabledClass: "swiper__navigation--disabled",
      },
      breakpoints: {
        576: {
          slidesPerView: 3,
        },
        420: {
          slidesPerView: 2,
        },
      },
    });

    targets.forEach((target) => {
      target = target.cloneNode(true);

      const img = target.querySelector("img");
      const label = target.querySelector(".s-products__label");

      img.setAttribute("title", label.textContent.trim());

      label.remove();

      target = target.outerHTML;

      swiper.appendSlide(`<div class="swiper-slide">${target}</div>`);
    });
  };

  const successStoriesSlider = () => {
    new Swiper(".s-success-stories__swiper", {
      slidesPerView: 1,
      spaceBetween: 5,
      navigation: {
        nextEl: ".s-success-stories__next",
        prevEl: ".s-success-stories__prev",
        disabledClass: "swiper__navigation--disabled",
      },
      breakpoints: {
        1440: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
      },
    });
  };

  const init = () => {
    servicesSlider();
    productsSlider();
    successStoriesSlider();
  };

  init();
})();
