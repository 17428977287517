(() => {
  const header = document.querySelector(".l-header");
  const navbar = document.querySelector(".navbar");
  const toggler = document.querySelector(".navbar-toggler");

  const handleNavbarOnScroll = () => {
    const addMenuScrolling = () => {
      header.classList.add("l-header--scrolling");
      navbar.classList.replace("navbar-dark", "navbar-light");
    };

    const removeMenuScrolling = () => {
      header.classList.remove("l-header--scrolling");
      navbar.classList.replace("navbar-light", "navbar-dark");
    };

    const handleScroll = () => {
      const scroll = scrollY;

      if (scroll > 0) addMenuScrolling();
      else removeMenuScrolling();
    };

    handleScroll();
    addEventListener("scroll", handleScroll);
  };

  const handleTogglerClick = () => {
    const addNavbarLight = () => {
      header.classList.add("l-header--scrolling");
      navbar.classList.replace("navbar-dark", "navbar-light");
    };

    const removeNavbarLight = () => {
      setTimeout(() => {
        header.classList.remove("l-header--scrolling");
        navbar.classList.replace("navbar-light", "navbar-dark");
      }, 350);
    };

    const toggleNavbarColor = () => {
      if (scrollY > 0) return;

      navbar.classList.contains("navbar-dark") ? addNavbarLight() : removeNavbarLight();
    };

    toggler.addEventListener("click", toggleNavbarColor);
  };

  const handleLinksClick = () => {
    const links = document.querySelectorAll(".l-header .nav-link:not([target='_blank'])");

    const closeMenu = () => {
      if (innerWidth >= 1200) return;

      toggler.click();
    };

    links.forEach((link) => {
      link.addEventListener("click", closeMenu);
    });
  };

  const init = () => {
    handleNavbarOnScroll();
    handleTogglerClick();
    handleLinksClick();
  };

  init();
})();
