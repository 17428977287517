(() => {
  const dynamicYear = () => {
    const actualYear = new Date().getFullYear();
    const target = document.querySelectorAll("[data-dynamic='year']");

    target.forEach((el) => {
      el.textContent = actualYear;
    });
  };

  const init = () => {
    dynamicYear();
  };

  init();
})();
